.textfield:focus-within ~ label,
.textfield:not(:placeholder-shown) ~ label {
  @apply transform -translate-y-6 duration-300;
}

.textfield2:focus-within ~ label,
.textfield2:not(:placeholder-shown) ~ label {
  @apply transform -translate-y-3.5 duration-300;
  font-size: 12px;
}

.textfield2:focus-within ~ input,
.textfield2:not(:placeholder-shown) ~ input {
  padding-bottom: 8px;
}
